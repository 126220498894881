body {
    background-color: lightgray;
}
section.content {
    margin-top: 70px;
		&.lightbox {
			margin-left: 10px;
			margin-top: 10px;
		}
}

.login-page .alert ul {
	list-style: none;
}
.dataTables_wrapper .row {
	margin: 0;
}
#leftsidebar {
	overflow: auto;
	li.header {
		border-top: 30px solid lightgray;
		&:first-child {
			border: none;
		}
	}
	.menu {
		overflow: visible;
	}
}
#updateMenu {
	#runningContainer {
		position: absolute;
		display: none;
		top: 37px;
		left: 0;
		height: 243px;
		text-align: center;
		width: 270px;
		#animContainer {
			width: 100px;
			height: 100px;
			margin: 0 auto;
			svg {
				background: none;
				height: 100%;
				width: 100%;
			}
		}
	}
	&.running {
		ul {
			opacity: 0.1;
		}
		#runningContainer {
			display: block;
		}
	}
	button {
		width: 100%;
	}
}
.bootstrap-select .dropdown-menu li.selected a {
    background-color: #99f3ff !important;
    color: #555 !important; }

table.bilan{
	white-space: nowrap;
	th {
		text-align: center;
		span {
			border-bottom: 1px solid;
		}
	}
}
.navbar-header {
	padding-bottom: 0;
	padding-top: 0;
}
.navbar-nav > li > a {
	margin-top: 7px;
}
.sidebar {
	top:50px;
	.user-info {
		height: 50px;
		padding-top: 5px;
		.info-container {
			top: 0;
		}
	}
}

.col-pink a {
    color: #E91E63 !important;
}
.content {
	&.lightbox .container-fluid {
    position: absolute;
    right: 5px;
    bottom: 15px;
    top: 15px;
    left: 5px;
		& .card .body {
			overflow: auto;
		}
	}
	.container-fluid {
		position: absolute;
		right: 5px;
		bottom: 20px;
		top: 65px;
		left: 301px;
		> .row {
			height: 100%;
			& > div {
				height: 100%;
				& > div.card {
					/*height:100%;*/
					margin-bottom: 0;
					& > div.body {
						/*position: absolute;*/
						/*top: 60px;*/
						width: 100%;
						/*bottom: 0;*/
						padding-right: 0;
						& div.tab-content {
							/*bottom: 0;*/
							/*position: absolute;*/
							/*top: 61px;*/
							left: 0;
							right: 0;
						}
					}
				}
			}
		}
	}
}
#full {
	/*position: absolute;*/
	/*top: 13px;*/
	/*bottom: 0;*/
	width: 100%;
	.table-responsive {
		/*position: absolute;*/
		width: 100%;
		top:0;
		bottom:0;
		.dataTables_wrapper {
			/*position: absolute;*/
			width:100%;
			bottom:0;
			top: 0;
			left:0;
			& > .row {
				/*position: absolute;*/
				width: 100%;
				&:first-child {
					top:0;
				}
				&:nth-child(2) {
					top:32px;
					bottom: 104px;
					overflow-y: auto;
					left: 0;
				}
				&:nth-child(3) {
					bottom: 0;
				}
			}
		}
	}
}
@media (max-width: 1186px) {
	.content .container-fluid {
		left: 0;
	}
	#full {
		position: static;
		.table-responsive {
			position: static;
			.dataTables_wrapper {
				position: static;
				& > .row {
					position: static;
				}
			}
		}
	}
	.content {
		.container-fluid {
			> .row {
				& > div {
					& > div.card {
						height:auto;
						& > div.body {
							position: static;
							bottom: auto;
							& div.tab-content {
								bottom: auto;
								position: static;
							}
						}
					}
				}
			}
		}
	}
}